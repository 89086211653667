import firebaseApp from 'firebase';
import { PermitStore } from './store/PermitStore';
import moment from 'moment';

const fbDb = firebaseApp.firestore();

export const getPermits = () => {
    fbDb.collection('permits')
        .get()
        .then((querySnapshot) => {
            var permitsArray = [];
            querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
                // console.log(doc.id, doc.data());
                permitsArray.push({ id, ...data });
            });
            PermitStore.update((s) => {
                s.permits = permitsArray;
            });
        });
};

export const getCurrentPermits = () => {
    fbDb.collection('permits')
        .get()
        .then((querySnapshot) => {
            var currentPermitsArray = [];
            querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
                // console.log(doc.id, doc.data());
                if (moment(data.to).format() > moment().format()) {
                    currentPermitsArray.push({ id, ...data });
                }
            });
            PermitStore.update((s) => {
                s.currentPermits = currentPermitsArray;
            });
        });
};
