import {
    IonContent,
    IonCard,
    IonPage,
    IonButton,
    IonRow,
    IonCol,
    IonGrid,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonSpinner,
    IonIcon,
    IonToolbar,
    IonButtons,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonToggle,
    IonListHeader,
    IonTitle,
    IonHeader,
    IonModal,
} from '@ionic/react';
import {
    banOutline,
    closeOutline,
    downloadOutline,
    mapOutline,
    thumbsDown,
    thumbsDownOutline,
    thumbsUp,
    thumbsUpOutline,
} from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import './Login.css';
// import { useHistory } from 'react-router';
import HeaderBar from '../components/HeaderBar';
import { PDFViewer, PDFDownloadLink, Document } from '@react-pdf/renderer';
import firebaseApp, { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Terms from '../components/Terms';
import PermitPDF from '../components/PermitPDF';
import Reasons from '../assets/data/Reasons';
import { toast } from '../components/toast';

const PDF = (props) => {
    const { permit } = props;
    return (
        <Document title={permit.id} fileName={permit.id} author='FirePermit.Online'>
            <PermitPDF permit={permit} />
            <Terms />
        </Document>
    );
};
const Permit = (props) => {
    const [user] = useAuthState(auth);
    const [permit, setPermit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState({
        isOpen: false,
        data: null,
    });
    const [reason, setReason] = useState({ reason: null, desc: null, sendViaEmail: false });
    const cancelReasons = Reasons;

    const fbDb = firebaseApp.firestore().collection('permits');
    const getPermit = () => {
        if (user) {
            fbDb.doc(props.match.params.id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const data = doc.data();
                        const id = doc.id;
                        setPermit({ id, ...data });
                        // console.log('Document data:', doc.data());
                    } else {
                        // doc.data() will be undefined in this case
                        console.log('No such document!');
                    }
                })
                .catch(function (error) {
                    console.log('Error getting document:', error);
                });
        }
    };
    // const setActive = (value, permit) => {
    //   fbDb.doc(value).update({
    //     active: !permit.active
    //   }).then(() => {
    //     getPermit();
    //     toast('Permit Status Updated!')
    //   })
    // }
    const setInspectionStatus = (id, val) => {
        // console.log(id, val);
        fbDb.doc(id)
            .update({
                inspectionStatus: val,
            })
            .then(() => {
                firebaseApp
                    .firestore()
                    .collection('users')
                    .doc(user)
                    .collection('permits')
                    .doc(id)
                    .update({
                        inspectionStatus: val,
                    });
            })
            .then(() => {
                getPermit();
            })
            .catch((err) => console.log(err));
        toast('Permit Inspection Status Updated');
    };
    const confirmCancel = (id, status, user) => {
        // console.log(id, status, user);
        if (status === 'Cancelled') {
            fbDb.doc(id)
                .update({
                    active: false,
                    reason: null,
                })
                .then(() => {
                    firebaseApp
                        .firestore()
                        .collection('users')
                        .doc(user)
                        .collection('permits')
                        .doc(id)
                        .update({
                            active: false,
                            reason: null,
                        });
                })
                .then(() => {
                    getPermit();
                    setShowModal({
                        isOpen: false,
                        data: null,
                    });
                    toast('Permit Reinstated');
                });
        } else {
            fbDb.doc(id)
                .update({
                    active: 'Cancelled',
                    reason,
                })
                .then(() => {
                    firebaseApp
                        .firestore()
                        .collection('users')
                        .doc(user)
                        .collection('permits')
                        .doc(id)
                        .update({
                            active: 'Cancelled',
                            reason,
                        });
                })
                .then(() => {
                    getPermit();
                    setShowModal({
                        isOpen: false,
                        data: null,
                    });
                    toast('Permit Cancelled');
                });
        }
        if (reason.sendViaEmail === true) {
            const sendEmail = firebaseApp.functions().httpsCallable('cxlEmail');
            sendEmail({ reason: reason.desc })
                .then(() => {
                    console.log('email request sent');
                    toast('Notification sent via Email');
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const clearCancel = () => {
        setShowModal({
            isOpen: false,
            data: null,
        });
        setReason({ reason: null, desc: null });
    };

    useEffect(() => {
        getPermit();
    });
    // useEffect(() => {
    //   console.log(permit)
    // }, [permit])
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    });
    return (
        <IonPage>
            <HeaderBar title='Success' />
            <IonContent>
                <IonGrid className='contentArea'>
                    <IonCard mode='ios'>
                        <IonCardHeader className='ion-margin-top'>
                            <IonCardSubtitle>Burn Permit</IonCardSubtitle>
                            <IonCardTitle>{permit && permit.id}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                {isLoading ? (
                                    'Loading...'
                                ) : (
                                    <IonRow>
                                        <IonCol>
                                            <IonToolbar>
                                                <IonButtons slot='start'>
                                                    <PDFDownloadLink
                                                        document={
                                                            <PDF
                                                                id={props.match.params.id}
                                                                permit={permit}
                                                                user={user}
                                                            />
                                                        }
                                                        fileName={`${props.match.params.id}`}
                                                    >
                                                        {({ blob, url, loading, error }) =>
                                                            loading ? (
                                                                'Loading document...'
                                                            ) : (
                                                                <IonIcon
                                                                    slot='start'
                                                                    icon={downloadOutline}
                                                                />
                                                            )
                                                        }
                                                    </PDFDownloadLink>
                                                </IonButtons>
                                                <IonButtons slot='end'>
                                                    <IonButton
                                                        shape='round'
                                                        href={`https://www.google.com/maps/search/?api=1&query=${permit.latitude},${permit.longitude}`}
                                                        target='_blank'
                                                    >
                                                        <IonIcon
                                                            icon={mapOutline}
                                                            // slot='icon-only'
                                                        />
                                                        {'  '}
                                                        &nbsp; Show on Map
                                                    </IonButton>
                                                    <IonButton
                                                        onClick={() =>
                                                            setInspectionStatus(permit.id, 'Passed')
                                                        }
                                                        disabled={
                                                            permit.inspectionStatus === 'Cancelled'
                                                        }
                                                    >
                                                        <IonIcon
                                                            icon={
                                                                permit.inspectionStatus === 'Passed'
                                                                    ? thumbsUp
                                                                    : thumbsUpOutline
                                                            }
                                                            slot='icon-only'
                                                            color={
                                                                permit.inspectionStatus === 'Passed'
                                                                    ? 'success'
                                                                    : 'medium'
                                                            }
                                                        />
                                                    </IonButton>
                                                    <IonButton
                                                        onClick={() =>
                                                            setInspectionStatus(permit.id, 'Failed')
                                                        }
                                                        disabled={
                                                            permit.inspectionStatus === 'Failed'
                                                        }
                                                    >
                                                        <IonIcon
                                                            icon={
                                                                permit.inspectionStatus === 'Failed'
                                                                    ? thumbsDown
                                                                    : thumbsDownOutline
                                                            }
                                                            slot='icon-only'
                                                            color={
                                                                permit.inspectionStatus === 'Failed'
                                                                    ? 'danger'
                                                                    : 'medium'
                                                            }
                                                        />
                                                    </IonButton>
                                                    <IonButton
                                                        onClick={() => {
                                                            // console.log(permit);
                                                            setShowModal({
                                                                isOpen: true,
                                                                data: permit,
                                                            });
                                                            setPermit(permit);
                                                        }}
                                                    >
                                                        <IonIcon
                                                            icon={banOutline}
                                                            slot='icon-only'
                                                        />
                                                    </IonButton>
                                                </IonButtons>
                                            </IonToolbar>
                                        </IonCol>
                                    </IonRow>
                                )}
                                <IonRow>
                                    <IonCol>
                                        {isLoading ? (
                                            <IonSpinner />
                                        ) : (
                                            <PDFViewer
                                                style={{ width: '100%', minHeight: '750px' }}
                                            >
                                                <PDF
                                                    id={props.match.params.id}
                                                    permit={permit}
                                                    user={user}
                                                />
                                            </PDFViewer>
                                        )}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </IonGrid>
                <IonModal
                    mode='ios'
                    isOpen={showModal.isOpen}
                    cssClass='my-custom-class'
                    swipeToClose={true}
                    onDidDismiss={clearCancel}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Cancel Permit?</IonTitle>
                            <IonButtons slot='end'>
                                <IonIcon
                                    slot='icon-only'
                                    icon={closeOutline}
                                    onClick={() =>
                                        setShowModal({
                                            isOpen: false,
                                            data: null,
                                        })
                                    }
                                />
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className='ion-padding'>
                        <p className='ion-text-center ion-padding-bottom'>
                            <strong>{showModal.data ? showModal.data.id : null}</strong>
                        </p>
                        <IonItem>
                            <IonLabel position='stacked' className='ion-padding-bottom'>
                                Reason
                            </IonLabel>
                            <IonSelect
                                placeholder='Select One'
                                onIonChange={(e) =>
                                    setReason(
                                        e.detail.value !== 'Other'
                                            ? cancelReasons[e.detail.value]
                                            : { reason: 'Other', desc: null }
                                    )
                                }
                            >
                                {cancelReasons &&
                                    cancelReasons.map((rea, i) => (
                                        <IonSelectOption key={i} value={i}>
                                            {rea.reason}
                                        </IonSelectOption>
                                    ))}
                                <IonSelectOption value='Other'>Other</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        {reason !== null ? (
                            <IonItem>
                                <IonLabel position='stacked' className='ion-padding-bottom'>
                                    Explanation
                                </IonLabel>
                                {reason && reason.reason === 'Other' ? (
                                    <IonTextarea
                                        rows='4'
                                        placeholder='Enter reasoning here...'
                                        value={reason.desc}
                                        onIonChange={(e) =>
                                            setReason({ reason: 'Other', desc: e.detail.value })
                                        }
                                    ></IonTextarea>
                                ) : reason && reason !== null ? (
                                    <p>{reason.desc}</p>
                                ) : null}
                            </IonItem>
                        ) : null}
                        <IonListHeader>Notify user?</IonListHeader>
                        <IonItem>
                            <IonLabel>Via email</IonLabel>
                            <IonToggle
                                disabled
                                checked={reason.sendViaEmail}
                                onIonChange={(e) =>
                                    setReason({ ...reason, sendViaEmail: e.detail.checked })
                                }
                            />
                        </IonItem>
                    </IonContent>
                    <IonButton
                        disabled={reason === null}
                        onClick={() =>
                            confirmCancel(
                                showModal.data.id,
                                showModal.data.active,
                                showModal.data.user
                            )
                        }
                    >
                        Confirm Cancellation
                    </IonButton>
                    <IonButton color='medium' onClick={clearCancel}>
                        Close
                    </IonButton>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default Permit;
