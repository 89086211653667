import React, { useState } from 'react';
import './CustomMarker.css';
import { IonItem, IonIcon, IonText, IonLabel } from '@ionic/react';
import { IonPopover } from '@ionic/react';
import { medicalSharp, bodySharp, banSharp } from 'ionicons/icons';

const ExclusionMarker = (props) => {
    const { color, pop, data, type } = props;
    const [showPopover, setShowPopover] = useState({ open: false, event: null });

    return (
        <div>
            {pop === true ? (
                <IonPopover
                    animated
                    showBackdrop={false}
                    backdropDismiss
                    event={showPopover.event}
                    cssClass='ion-padding'
                    isOpen={showPopover.open}
                    onDidDismiss={(e) => setShowPopover({ open: false, event: null })}
                >
                    <IonItem
                        lines='none'
                        detail
                        routerLink={'/exclusions'}
                        onClick={() => setShowPopover({ open: false, event: null })}
                    >
                        <IonLabel className='ion-text-wrap'>
                            {data.address === 'other' ? data.otherAddress : data.address} -{' '}
                            <IonText className='ion-text-capitalize'>{data.reason}</IonText>
                        </IonLabel>
                    </IonItem>
                </IonPopover>
            ) : null}
            <IonIcon
                onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}
                icon={
                    type && type === 'health'
                        ? medicalSharp
                        : type && type === 'compliance'
                        ? bodySharp
                        : type && type === 'property'
                        ? banSharp
                        : banSharp
                }
                className='flamer'
                color={color}
                size=''
            />
        </div>
    );
};

export default ExclusionMarker;
