import {
    IonContent,
    IonCard,
    IonPage,
    IonButton,
    IonProgressBar,
    IonRow,
    IonCol,
    IonGrid,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonSpinner,
    IonLoading,
} from '@ionic/react';
import { documentOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import './Login.css';
import { useHistory } from 'react-router';
import HeaderBar from '../components/HeaderBar';
import { PDFViewer, PDFDownloadLink, Document } from '@react-pdf/renderer';
import firebaseApp, { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import PermitPDF from '../components/PermitPDF';
import Terms from '../components/Terms';

const PDF = (props) => {
    const { permit } = props;
    return (
        <Document title={permit.id} fileName={permit.id} author='FirePermit.Online'>
            <PermitPDF permit={permit} />
            <Terms />
        </Document>
    );
};
const Success = (props) => {
    // console.log(props)
    const history = useHistory();
    const [user] = useAuthState(auth);
    const [permit, setPermit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const viewPermits = () => {
        history.replace('/permits');
        window.location.reload(true);
    };
    const fbDb = firebaseApp.firestore().collection('permits');

    useEffect(() => {
        const getPermit = () => {
            if (user !== null) {
                fbDb.doc(props.match.params.id)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const id = doc.id;
                            setPermit({ id, ...data });
                            // console.log('Document data:', doc.data());
                        } else {
                            // doc.data() will be undefined in this case
                            console.log('No such document!');
                        }
                    })
                    .catch(function (error) {
                        console.log('Error getting document:', error);
                    });
            }
        };
        getPermit();
    }, [fbDb, props.match.params.id, user]);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
    });
    if (permit) {
        return (
            <IonPage>
                <HeaderBar title='Success' />
                <IonContent>
                    <IonGrid className='contentArea'>
                        <IonCard mode='ios'>
                            <IonCardHeader className='ion-margin-top'>
                                <IonCardSubtitle>Burn Permit Application</IonCardSubtitle>
                                <IonCardTitle>Success!</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonProgressBar value={1}></IonProgressBar>
                                <br />
                                <IonGrid>
                                    {isLoading ? (
                                        'Loading...'
                                    ) : (
                                        <IonRow>
                                            <IonCol>
                                                <PDFDownloadLink
                                                    document={
                                                        <PDF
                                                            id={props.match.params.id}
                                                            permit={permit}
                                                            user={user}
                                                        />
                                                    }
                                                    fileName={`${props.match.params.id}`}
                                                >
                                                    {({ blob, url, loading, error }) =>
                                                        loading ? (
                                                            'Loading document...'
                                                        ) : (
                                                            <IonButton>
                                                                <IonIcon
                                                                    slot='start'
                                                                    icon={documentOutline}
                                                                />{' '}
                                                                Download Permit PDF
                                                            </IonButton>
                                                        )
                                                    }
                                                </PDFDownloadLink>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                    <IonRow>
                                        <IonCol>
                                            {isLoading ? (
                                                <IonSpinner />
                                            ) : (
                                                <PDFViewer
                                                    style={{ width: '100%', minHeight: '750px' }}
                                                >
                                                    <PDF
                                                        id={props.match.params.id}
                                                        permit={permit}
                                                        user={user}
                                                    />
                                                </PDFViewer>
                                            )}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <p className='ion-text-center'>
                                                <IonButton onClick={viewPermits}>
                                                    View All Permits
                                                </IonButton>
                                            </p>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardContent>
                        </IonCard>
                    </IonGrid>
                </IonContent>
            </IonPage>
        );
    } else {
        return <IonLoading />;
    }
};

export default Success;
