import { 
  IonContent,
  IonCard,
  IonPage,
  IonImg,
  IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonText,
  IonGrid,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Login.css';
import { auth } from "../firebase";
import HeaderBar from '../components/HeaderBar';
import fpoLogo from '../assets/images/fpoLogo.png';

const Login = ({history}) => {
  const [email, setEmail] = useState(``);
  const [password, setPassword] = useState(``);

  const [error, setError] = useState(``);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if(user) {
        history.push('/dashboard', {direction: 'none'});
      }
    })
  }, [history]);
  
  const signInWithEmailAndPasswordHandler = 
    (event, email, password) => {
      event.preventDefault();
      setError(``);

      if (email.length < 4) {
        setError(`Name must be at least 4 characters.`);
      }
      
      if (password.length < 4) {
        setError(`Password must be at least 4 characters.`);
      }

      if (error === ``) {
        auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          console.log('logged in');
          history.push('/dashboard', {direction: 'none'});
        })
        .catch( e => {
          setError(e.message);
        });
      }
    };

  return (
    <IonPage>
      <HeaderBar title="Log in"/>
      <IonContent>
        <IonGrid className="contentArea maxSix">
          <IonRow>
            <IonCol>
              <IonImg style={{ width:'100%', margin: '0 auto' }} className="ion-padding" src={fpoLogo} />  
            </IonCol>
          </IonRow>
          <IonCard className="cardContainer ion-margin-top" mode="ios">
            <IonCardHeader className="ion-margin-top">
              <IonCardSubtitle>Log in to view</IonCardSubtitle>
              <IonCardTitle>Your Account</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              
              <IonList>
              <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput 
                    value={email}
                    type="text"
                    placeholder="Enter Email"
                    onIonChange={e => setEmail(e.detail.value)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    value={password}
                    type="password"
                    placeholder="Enter Password"
                    onIonChange={e => setPassword(e.detail.value)}
                  ></IonInput>
                </IonItem>
              </IonList>
              <br/>
              <IonButton 
                expand="block"
                color="primary"
                onClick={
                  event => {
                    event.preventDefault();
                    signInWithEmailAndPasswordHandler(event, email, password);
                  }
                }
              >Log in</IonButton>
              
              <IonRow>
                <IonCol>
                {error !== '' && (
                  <IonText color="danger">
                    <p className="ion-padding-start">
                    {error}
                    </p>
                  </IonText>
                )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton routerLink="/forgot" color="primary" fill="clear" expand="block">Forgot Password</IonButton>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
          
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
