import React, { useState } from 'react';
import './CustomMarker.css';
import { IonItem, IonIcon } from '@ionic/react';
import { IonPopover } from '@ionic/react';
import { flameSharp, medicalSharp, bodySharp, banSharp } from 'ionicons/icons';

const FireMaker = (props) => {
    const { color, pop, data, type } = props;
    const [showPopover, setShowPopover] = useState({ open: false, event: null });

    return (
        <div>
            {pop === true ? (
                <IonPopover
                    animated
                    showBackdrop={false}
                    backdropDismiss
                    event={showPopover.event}
                    cssClass='ion-padding'
                    isOpen={showPopover.open}
                    onDidDismiss={(e) => setShowPopover({ open: false, event: null })}
                >
                    <IonItem
                        lines='none'
                        detail
                        routerLink={`/permit/${data.id}`}
                        onClick={() => setShowPopover({ open: false, event: null })}
                    >
                        {data.address === 'other' ? data.otherAddress : data.address} -{' '}
                        {data.active ? 'Active' : 'Not Active'}
                    </IonItem>
                </IonPopover>
            ) : null}
            <IonIcon
                onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}
                icon={
                    type && type === 'health'
                        ? medicalSharp
                        : type && type === 'compliance'
                        ? bodySharp
                        : type && type === 'property'
                        ? banSharp
                        : flameSharp
                }
                className='flamer'
                color={color}
                size=''
            />
        </div>
    );
};

export default FireMaker;
