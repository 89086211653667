import { IonRow, IonCol, IonLabel, IonToggle, IonItem } from '@ionic/react';
import React from 'react';
import { PermitStore } from '../store/PermitStore';

const CurrentToggle = () => {
    const currentOnly = PermitStore.useState((s) => s.currentOnly);
    return (
        <IonRow>
            <IonCol>
                <IonItem>
                    <IonLabel>
                        {currentOnly ? 'Showing Only Current Permits' : 'Including Expired Permits'}
                    </IonLabel>
                    <IonToggle
                        mode='ios'
                        color='success'
                        checked={currentOnly}
                        onIonChange={() =>
                            PermitStore.update((s) => {
                                s.currentOnly = !currentOnly;
                            })
                        }
                    />{' '}
                </IonItem>
            </IonCol>
        </IonRow>
    );
};

export default CurrentToggle;
