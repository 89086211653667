const Reasons = [
    {
        "reason": "Property deficient",
        "desc": "In accordance with the by-law, the property does not meet the requirements for a permit.  It is the responsibility of the applicant to ensure that the property meets all requirements of the by-law before submitting an application for a permit."
    },
    {
        "reason": "Non-compliance",
        "desc": "Your fire permit has been revoked for non-compliance."
    },
    {
        "reason": "Health concern",
        "desc": "Your permit has been cancelled because an individual with a health condition that is aggravated by smoke, or with an increased sensitivity to air pollution or environmental contaminants, has registered an address near you."
    }
]

export default Reasons