import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
});

const Terms = () => {
    return (
        <>
            <Page size='A4' style={styles.page}>
                <View style={styles.section}>
                    <View style={{ marginVertical: 10 }}>
                        <Text
                            style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 18,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                marginVertical: 5,
                            }}
                        >
                            PLEASE CAREFULLY REVIEW THE REQUIREMENTS FOR THE TYPE OF PERMIT YOU ARE
                            APPLYING FOR.
                        </Text>
                        <Text
                            style={{
                                fontSize: 12,
                                lineHeight: 1.4,
                                marginBottom: 7.5,
                                textAlign: 'center',
                            }}
                        >
                            If the open air fire does not meet the requirements of the by-law, the
                            permit may be revoked. No refund of the application fee will be provided
                            for non-compliance of the by-law.
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 16,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        FARM FIRE PERMIT
                    </Text>
                    <View style={{ marginLeft: 15 }}>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Issued to owners or operators of agricultural operations (as defined by
                            the Farming and Food Protection Act) to dispose of clean,unpainted wood
                            and natural vegetation ON AGRICULTURAL ZONED LANDS ONLY, consistent with
                            normal farming practice.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Fires are allowed only BETWEEN SUNRISE AND SUNSET on any day.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Fire must be CONFINED to a SINGLE Fire Pit or pile that MAY NOT EXCEED 6
                            metres (approximately 20 feet) in diameter or 2 metres(approx. 6 feet)
                            in height, unless otherwise allowed by the Fire Chief under an approved
                            Burning Safety Plan.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Maintain MINIMUM PERIMETER of 5 metres (approx. 16 feet) clear of
                            combustible material and dry vegetation around the fire.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Maintain MINIMUM DISTANCE of 60 metres (approx. 200 feet) between the
                            fire and any building, structure, fence, property line,roadway, railway,
                            overhead wire, or combustible material.
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 16,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        URBAN FIRE PERMIT
                    </Text>
                    <View style={{ marginLeft: 15 }}>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Property lot size must be MINIMUM WIDTH of 12 metres (approximately 40
                            feet).
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Fires are allowed only BETWEEN 4 P.M. and 11 P.M. on any day.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            CONFINE fire to an Outdoor Fireplace or Fire Pit NO LARGER THAN 61
                            centimetres in diameter (approx. 2 feet) and the height of thematerial
                            being burned is no greater than 61 centimetres.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Maintain MINIMUM DISTANCE of 6 metres (approx. 20 feet) between the fire
                            and any adjacent property or roadway.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Maintain MINIMUM DISTANCE of 5 metres (approx. 16 feet) between the fire
                            and any building, structure, fence, overhead wire, or combustibl
                            ematerial.
                        </Text>
                        <Text
                            Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            NOT allowed on a combustible surface or deck, or on or near dry grass or
                            vegetation.
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 16,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        RURAL FIRE PERMIT
                    </Text>
                    <View style={{ marginLeft: 15 }}>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            NOT permitted in an Urban Area.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Fires are allowed only BETWEEN 8 A.M. AND 11 P.M. on any day
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            CONFINE fire to an Outdoor Fireplace or Fire Pit NO LARGER THAN 1 metre
                            (approx. 3 feet) in diameter and the height of thematerial being burned
                            is no greater than 1 metre.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Maintain MINIMUM DISTANCE of 10 metres (approx. 33 feet) between the
                            fire and any building, structure, fence, property line,roadway, overhead
                            wire, or combustible material.
                        </Text>
                    </View>
                </View>
            </Page>
            <Page size='A4' style={styles.page}>
                <View style={{ height: 30 }} />
                <View style={styles.section}>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 16,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        CAMPGROUND FIRE PERMIT
                    </Text>
                    <View style={{ marginLeft: 15 }}>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            A BURNING SAFETY PLAN must be submitted with the Campground Fire Permit
                            application, and approved by the Fire Chief.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Each Campground fire must be confined to a Fire Pit or Outdoor
                            Fireplace.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Maintain MINIMUM of 3 metres (approx. 10 feet) between each fire and any
                            building, structure, fence, or combustible material.
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 16,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        SPECIAL EVENT FIRE PERMIT
                    </Text>
                    <View style={{ marginLeft: 15 }}>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Applicant represents a not-for-profit community organization, school, or
                            religious institution;
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Purpose of the fire is for a specific cultural, religious, or
                            recreational event; and
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            The permit may be subject to additional conditions imposed by the Fire
                            Chief.
                        </Text>
                    </View>
                    <Text
                        style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 16,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            marginVertical: 5,
                        }}
                    >
                        REGULATIONS FOR ALL OPEN AIR FIRES
                    </Text>
                    <View style={{ marginLeft: 15 }}>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            ALL Open Air Fires in the Town of Lincoln require a valid Open Air Fire
                            Permit, except for small fires for cooking food that arecontained
                            entirely within a fixed or portable barbecue or within a metal or
                            masonry enclosure with a metal grill or screen on top, the size of the
                            fire is reasonable for the quantity of food, only commercially produced
                            charcoal, briquettes or clean, dry, seasoned wood are used, and is
                            supervised at all times by a Competent Person. Listed gas-fired outdoor
                            appliances are exempt.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            NO prohibited materials may be burned (including garbage, residential,
                            commercial, industrial, construction, or demolition wastehazardous
                            materials, tires, plastic materials, furniture, appliances, and
                            upholstered items, treated or painted wood, plywood, composite wood
                            products, leaves and grass clippings, animal fecal deposits, manure, and
                            animal carcasses, and paper or cardboard except for reasonable minimal
                            amounts used solely for starting an Open Air Fire).
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            MUST be confined to a Fire Pit or Outdoor Fireplace. NO grass fires are
                            allowed.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Smoke from the fire NOT allowed to blow across a public road or highway
                            thereby decreasing visibility to motorists.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Smoke, odour, ashes, or embers from the fire NOT allowed to invade a
                            neighbouring property thereby creating a Nuisance.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            NOT allowed if the wind velocity is 20 kilometres per hour or greater.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Smoke from the fire NOT allowed to blow across a public road or highway
                            thereby decreasing visibility to motorists.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            NOT allowed during rainy or foggy weather or when a smog alert or air
                            quality advisory has been issued.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            Smoke from the fire NOT allowed to blow across a public road or highway
                            thereby decreasing visibility to motorists.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            MUST be a Competent Person 18 years of age or older in attendance to
                            supervise the fire during the entire time it is burning, andwho MUST
                            ensure that the fire is completely extinguished before leaving the site
                            where the fire was burning.
                        </Text>
                        <Text
                            style={{
                                fontFamily: 'Helvetica',
                                fontSize: 12,
                                textAlign: 'center',
                                marginVertical: 5,
                            }}
                        >
                            MUST have adequate means to control and extinguish the fire on hand and
                            ready for immediate use while fire is burning.
                        </Text>
                    </View>
                </View>
            </Page>
            <Page size='A4' style={styles.page}>
                <View style={styles.section}>
                    <Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 12,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        NOT allowed during a ban on open air burning declared by the Fire Chief.
                    </Text>
                    <Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 12,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        NOT allowed in a burn barrel, waste incinerator, or similar device.
                    </Text>
                    <Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 12,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        Flying Lanterns may NOT be ignited or released into the air within, or that
                        fly over, the geographical boundaries of the Town.
                    </Text>
                    <Text
                        style={{
                            fontFamily: 'Helvetica',
                            fontSize: 12,
                            textAlign: 'center',
                            marginVertical: 5,
                        }}
                    >
                        A copy of this Open Air Permit MUST be available for inspection upon request
                        at the site of the Open Air Fire.
                    </Text>
                </View>
            </Page>
        </>
    );
};

export default Terms;
