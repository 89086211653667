import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/functions';
import { toast } from './components/toast';

const firebaseConfig = {
  apiKey: "AIzaSyDHMgHxdq5hKkN817fcDALQCEjLQYqwM00",
  authDomain: "lincolnfirepermitsonline.firebaseapp.com",
  databaseURL: "https://lincolnfirepermitsonline.firebaseio.com",
  projectId: "lincolnfirepermitsonline",
  storageBucket: "lincolnfirepermitsonline.appspot.com",
  messagingSenderId: "30401353218",
  appId: "1:30401353218:web:8cecb9d2a208cb0ca2391b",
  measurementId: "G-5CX77TWRED"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
export default firebaseApp;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export async function loginUser(email, password) {
    try {
      const res = await firebase.auth().signInWithEmailAndPassword(email, password)
      console.log(res)
      return true
    } catch(error) {
      toast(error.message, 4000)
      return false
    }
  }

export function logoutUser() {
  return firebase.auth().signOut()
}

